<template>
  <div class="tree-box bb">
    <div class="flex-end pointer">
      <el-button
        v-show="showAllExpand"
        type="text"
        @click="showExpandFn('open')"
        class="el-icon-caret-bottom"
        :disabled="!treeData.length"
        >全部展开
      </el-button>
      <el-button
        class="el-icon-caret-top"
        v-show="!showAllExpand"
        @click="showExpandFn('close')"
        type="text"
        >全部收起
      </el-button>
    </div>
    <el-scrollbar
      style="height:100%"
      wrap-class="scrollbar-wrapper"
      v-if="treeData.length"
    >
      <el-tree
        v-if="openOrNot"
        ref="tree"
        :data="treeData"
        node-key="spaceMenuId"
        :default-expanded-keys="defaultshowKeys"
        :props="defaultProps"
        :expand-on-click-node="false"
        @node-click="nodeClickFn"
        @node-expand="nodeExpandFn"
        @node-collapse="nodeCollapseFn"
      >
        <!--  :default-expand-all="defaultExpandAll" -->
        <!-- :highlight-current="false" -->
        <span
          class="custom-tree-node"
          slot-scope="{ node, data }"
          :style="{
            background: isActiveRow(data) ? '#e8f1ff' : ''
          }"
        >
          {{ node.label }}
          <span>
            <el-popover
              placement="right"
              width="200"
              trigger="click"
              :ref="`popover-${data.spaceMenuId}`"
            >
              <div class="treeDialogBox bb font16">
                <p class="pointer" @click="addTreeNode(node, false)">
                  添加（二级）页面
                </p>
                <p
                  v-if="node.level == 1"
                  class="pointer"
                  @click="addTreeNode(node, true)"
                >
                  添加（一级）菜单
                </p>
                <p
                  class="pointer"
                  v-if="node.level == 1"
                  @click="delteFn(node)"
                >
                  删除菜单
                </p>
                <p
                  class="pointer"
                  v-if="node.level == 2"
                  @click="delteFn(node)"
                >
                  删除页面
                </p>
                <p class="line w100 bb pointer"></p>
                <p class="pointer" @click="renameFn(node)">编辑</p>
                <p class="line w100 bb pointer"></p>
                <p class="pointer" @click="moveUpFn(node)">上移</p>
                <p class="pointer" @click="moveDownFn(node)">下移</p>
                <p class="line w100 bb pointer"></p>
              </div>
              <el-button
                type="text"
                slot="reference"
                size="large"
                class="el-icon-more font16"
                @click.stop="treeMoreBtn(data)"
              />
            </el-popover>
          </span>
        </span> </el-tree
    ></el-scrollbar>
    <div class="flex-center">
      <el-button
        v-if="!treeData.length"
        style="margin-top:10px"
        type="primary"
        @click="addTreeNode(null, true)"
        >新增菜单</el-button
      >
    </div>

    <add_menu
      :title="addDialogTitle"
      :showDialog="addDialog"
      :ifEdit="ifEdit"
      :nodeData="currentClickNode"
      @closeDialog="closeAddFn"
      @sureDialog="sureAddFn"
    />
    <confirmDialog
      :showConfirmDialog="showConfirmDialog"
      @closeConfirm="closeConfirmFn"
      @sureConfirm="sureConfirmFn"
      ><span>
        确认删除 <label class="red">{{ confirmAction }}</label> 吗？
      </span>
    </confirmDialog>
  </div>
</template>

<script>
import {
  add_space_menu,
  menu_up,
  menu_down,
  remove_menu
} from '@/api/space_menus';

export default {
  name: 'index-left-tree',
  components: {
    add_menu: () => import('./add_menu.vue'),
    confirmDialog: () => import('@/views/components/confirmDialog.vue')
  },
  props: {
    treeData: {
      type: Array,
      default: []
    },
    spaceId: 0
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      editRree: false,
      inputVal: '',
      addDialogTitle: '',
      addDialog: false,
      showConfirmDialog: false,
      confirmAction: '',
      currentClickNode: null,
      ifEdit: false,
      add_1or2: null, //新增1级还是2级
      // defaultExpandAll: false,
      showAllExpand: true,
      openOrNot: true,
      defaultshowKeys: []
    };
  },
  mounted() {},
  methods: {
    //展开节点
    nodeExpandFn(data, node, obj) {
      this.showAllExpand = true;
      // console.log(data);
      let newObj = {};
      this.defaultshowKeys.forEach(cell => {
        newObj[cell] = cell;
      });
      data.children.forEach(ele => {
        if (!newObj[ele.spaceMenuId.toString()]) {
          this.defaultshowKeys.push(ele.spaceMenuId.toString());
        }
      });
      // console.log(this.defaultshowKeys, '展开后defaultExpandedKeys');
    },
    //收起节点
    nodeCollapseFn(data, node, obj) {
      this.showAllExpand = false;
      // console.log(data);
      data.children.forEach(ele => {
        this.defaultshowKeys.forEach((item, idx) => {
          if (item == ele.spaceMenuId.toString()) {
            this.defaultshowKeys.splice(idx, 1);
          }
        });
      });

      // console.log(this.defaultshowKeys, '收起后defaultExpandedKeys====');
      if (this.defaultshowKeys.length == 0) {
        this.showAllExpand = true;
      }
    },

    showExpandFn(type) {
      this.showAllExpand = !this.showAllExpand;
      if (type == 'close') {
        this.defaultshowKeys = [];
      } else {
        this.defaultshowKeys = [];
        let obj = [...new Set(this.$parent.expandNodes)];
        this.defaultshowKeys = obj;
      }
      this.openOrNot = false;
      setTimeout(() => {
        this.openOrNot = true;
      }, 50);
      // console.log(this.defaultshowKeys, 'defaultshowKeys22222');
    },

    isActiveRow(data) {
      if (this.currentClickNode) {
        const spaceMenuId_click = this.currentClickNode.data
          ? this.currentClickNode.data.spaceMenuId
          : this.currentClickNode.spaceMenuId;
        return data.spaceMenuId == spaceMenuId_click;
      } else {
        return false;
      }
    },
    closeConfirmFn() {
      this.showConfirmDialog = false;
    },
    sureConfirmFn() {
      const { data } = this.currentClickNode;
      this.remove_menu(data);
    },
    //删除
    delteFn(node) {
      this.showConfirmDialog = true;
      this.currentClickNode = node;
      this.confirmAction =
        node.data.parentId == undefined
          ? `一级菜单：${node.data.name}`
          : `二级页面：${node.data.name}`;
    },
    async remove_menu(data) {
      const res = await remove_menu({ spaceMenuId: data.spaceMenuId });
      this.$message.success(res.data);
      this.showConfirmDialog = false;
      this.$parent.get_meus();
    },

    //上移
    moveUpFn(node) {
      const { data } = node;
      this.menu_up(data);
    },
    async menu_up(data) {
      const res = await menu_up({ spaceMenuId: data.spaceMenuId });
      this.$message.success(res.data);
      this.$refs[`popover-${data.spaceMenuId}`].doClose();
      this.$parent.get_meus();
    },
    //下移
    moveDownFn(node) {
      const { data } = node;
      this.menu_down(data);
    },
    async menu_down(data) {
      const res = await menu_down({ spaceMenuId: data.spaceMenuId });
      this.$message.success(res.data);
      this.$refs[`popover-${data.spaceMenuId}`].doClose();
      this.$parent.get_meus();
    },
    //重命名
    renameFn(node) {
      this.addDialog = true;
      this.addDialogTitle = '编辑';
      this.ifEdit = true;
      this.currentClickNode = node;
    },
    treeMoreBtn(data) {
      document.body.click(); //模拟点击
    },

    nodeClickFn(node) {
      // if (node.parentId == undefined) {
      this.$emit('click_left_node', node);
      this.currentClickNode = node;
      // } else {
      //   this.$emit('click_left_node', null);
      // }
    },

    addTreeNode(node, isFirst) {
      this.currentClickNode = node;
      this.add_1or2 = isFirst ? 1 : 2;
      this.ifEdit = false;
      this.addDialog = true;
      if (!node) {
        this.addDialogTitle = '新增一级菜单';
        return;
      }
      const { data, parent } = node;
      let name = data.parentId == undefined ? data.name : parent.data.name;
      this.addDialogTitle =
        name + (isFirst ? '- 新增一级菜单' : ' - 新增二级页面');
    },

    async add__edit_space_menu(v, cb) {
      const res = await add_space_menu(v);
      cb && cb();
    },
    closeAddFn() {
      this.addDialog = false;
    },
    sureAddFn(v) {
      let node = this.currentClickNode;
      let parentId = '';
      let params = {};
      if (this.ifEdit) {
        params = { ...node.data };
        delete params.children;
      } else {
        if (this.add_1or2 == 2) {
          parentId =
            node.data.parentId == undefined
              ? node.data.spaceMenuId
              : node.parent.data.spaceMenuId;
        }
        params = {
          spaceId: this.spaceId.toString(),
          parentId: parentId
        };
      }
      // console.log(params, v);
      // return;
      this.add__edit_space_menu(
        {
          ...params,
          ...v
        },
        () => {
          this.$message.success(this.ifEdit ? '保存成功' : '新增成功');
          this.addDialog = false;
          this.$parent.get_meus();
          this.add_1or2 = null;
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.expand_box {
  margin: 10px 0;
}
.tree-box {
  margin-top: 8px;
  height: calc(100% - 100px);
  padding: 0 0 10px;
  ::v-deep {
    .el-tree-node__content {
      height: 40px;
    }

    .el-tree-node:focus > .el-tree-node__content {
      background-color: #e8f1ff !important;
    }
    .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      background-color: #e8f1ff !important;
    }
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  height: 38px;
  padding-left: 5px;
}
.treeDialogBox {
  width: 200px;
  padding: 5px;

  p {
    &:hover {
      background: #e8f1ff;
    }
    &:active {
      background: #e8f1ff;
    }
  }

  .line {
    height: 1px;
    background: #e5e5e5;
  }
}
</style>
